import { Subject } from 'rxjs';
// import { ToastMessageData } from '../../react/src/navigation/MainContent/services/Toast.types';

type ToastMessageData = any;
export type AppAction = {
  action: 'SHOW_TOAST'
  data: ToastMessageData
  callback: any
}

export const sharedAppSubject = new Subject<AppAction>();

export function showToast(input: Omit<AppAction, 'callback'>) {
  return new Promise<void>((resolve, reject) => {
    sharedAppSubject.next({
      ...input,
      callback: resolve
    });
  });
}
